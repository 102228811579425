@tailwind base;

/* Your own custom base styles */

/* Start purging... */
@tailwind components;
/* Stop purging. */

/* Your own custom component styles */

/* Start purging... */
@tailwind utilities;
/* Stop purging. */

/* Your own custom utilities */

@font-face {
    font-family: '4990810';
    src: url('../fonts/4990810-webfont.eot');
    src: url('../fonts/4990810-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/4990810-webfont.woff') format('woff'),
    url('../fonts/4990810-webfont.ttf') format('truetype'),
    url('../fonts/4990810-webfont.svg#4990810') format('svg');
    font-weight: normal;
    font-style: normal;
}

body, html, #root {
    @apply h-full font-os text-graydark;
}

.App {
}

.error {
    font-size: 14px;
    color: red;
    font-style: italic;
}

.top-divider {
    @apply border-0 border-solid border-t border-graylight mt-4 pt-4
}

.server-html p {
    @apply mb-4;
}

.server-html a {
    @apply text-blue;
}

h1 {
    font-size: 36px;
}

.server-html p:not(:last-child) {
    @apply mb-5;
}